/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { Button, Switch, Toolbar } from "@mui/material";
import styles from "../../Styles/financialTable.module.css";
import GenericModal from "../../Modal/GenericModal";
import { ThemeProvider } from "@emotion/react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import DinamicTabs from "../DinamicTabs";
import { exportTable, isMobile } from "../../Utils/utils";
import { componentNames, tableLayoutOnMobile } from "../../Utils/Constants";
import withRole from "../../Utils/withRole";
import { PillsProps, StateProps } from "../MainDashboard";
import { updateData } from "../../Services/updateData";
import { VehicleContext } from "../../Context/vehicleContext";
import { formatNumber } from "../../Utils/formatDate";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const VehicleStatus = {
  NotReceived: "NotReceived",
  Unsold: "Unsold",
} as const;

export interface MainTableProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

function TabPanel(props: MainTableProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

type TableProps = {
  data: any;
  pills: PillsProps;
  state: StateProps;
  value: number;
  info?: any;
  handleChange: (event: any, index: number) => void;
  getDataRefresh?: any;
  vehicleCounter?: any;
};
function MainTableFinancial(props: TableProps) {
  const {
    info,
    pills,
    state,
    value,
    handleChange,
    getDataRefresh,
    vehicleCounter,
  } = props;
  const navigate = useNavigate();
  const vehicleContext: any = useContext(VehicleContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCar, setSelectedCar]: any = useState();
  const [recoveredTVA, setRecoveredTVA]: any = useState();
  const tableLabels = [
    {
      label: Vocabulary.unpaidInvoices,
      badge: vehicleCounter.numberOfVehiclesUnpaid,
    },
    {
      label: Vocabulary.paidInvoices,
      badge: vehicleCounter.numberOfVehiclesPaid,
    },
    {
      label: Vocabulary.paidInAdvance,
      badge: vehicleCounter.numberOfVehiclesWithAdvancePaid,
    },
    {
      label: Vocabulary.contractToSign,
      badge: vehicleCounter.numberOfVehiclesWithContractToSign,
    },
    {
      label: Vocabulary.solds,
      badge: vehicleCounter.numberOfVehiclesWithContractSigned,
    },
  ];

  /**
   *
   */
  const columnsUnsold = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
        viewColumns: false,
      },
    },
    {
      name: "numeComplet",
      label: Vocabulary.nume,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (vin: any) => (
          <Typography style={{ fontSize: "0.875rem" }}>
            {isMobile() ? vin : "..." + vin.substring(vin.length - 6)}
          </Typography>
        ),
      },
    },
    {
      name: "status_vehicul_r",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "purchasePrice",
      label: Vocabulary.acquisitionPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (purchasePrice: any) => formatNumber(purchasePrice),
      },
    },
    // {
    //   name: "salePrice",
    //   label: Vocabulary.sellPrice,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (salePrice: any) => formatNumber(salePrice),
    //   },
    // },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (object: any) => (
          <Typography>{object?.nume}</Typography>
        ),
      },
    },
    {
      name: "user_achizitie_r",
      label: Vocabulary.user,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (user_r: any) => (
          <Typography>{user_r?.name}</Typography>
        ),
      },
    },
    {
      name: "date",
      label: Vocabulary.year,
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "tip_tva_r",
      label: Vocabulary.TVA,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (tip_tva_r: any) => (
          <Typography>{tip_tva_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "TvaRecovered",
      label: Vocabulary.recoveredTVA,
      options: {
        filter: true,
        sort: false,
        boolean: true,
        customBodyRender: (index: any, tvaRecovered: any) => (
          <div
            style={{ zIndex: 2000 }}
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          >
            <Switch
              defaultChecked={tvaRecovered?.rowData[9] === 0 ? false : true}
              onChange={(event: any) => {
                event.stopPropagation();
                handleTVARecoveredCar(tvaRecovered, tvaRecovered?.rowData[9]);
              }}
              value={value}
              id="tva_id"
            />
          </div>
        ),
      },
    },
    {
      name: "firma_achizitie_r",
      label: Vocabulary.nume_firma,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (firma: any) => (
          <Typography>{firma?.nume_firma}</Typography>
        ),
      },
    },
  ];
  /**
   *
   */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
        download: false,
      },
    },
    {
      name: "numeComplet",
      label: Vocabulary.name,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (vin: any) => (
          <Typography style={{ fontSize: "0.875rem" }}>
            {isMobile() ? vin : "..." + vin.substring(vin.length - 6)}
          </Typography>
        ),
      },
    },
    {
      name: "status_vehicul_r",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "purchasePrice",
      label: Vocabulary.acquisitionPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (purchasePrice: any) => formatNumber(purchasePrice),
      },
    },
    {
      name: "salePrice",
      label: Vocabulary.sellPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (salePrice: any) => formatNumber(salePrice),
      },
    },
    {
      name: "user_achizitie_r",
      label: Vocabulary.user,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (user_r: any) => (
          <Typography>{user_r?.name}</Typography>
        ),
      },
    },
    {
      name: "tip_tva_r",
      label: Vocabulary.TVA,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (tip_tva_r: any) => (
          <Typography>{tip_tva_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "date",
      label: Vocabulary.year,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firma_achizitie_r",
      label: Vocabulary.nume_firma,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (firma: any) => (
          <Typography>{firma?.nume_firma}</Typography>
        ),
      },
    },
  ];

  /**
   *
   * @param data
   */
  const handleTVARecoveredCar = (data?: any, value?: any) => {
    setSelectedCar(data);
    setRecoveredTVA(value === 0 ? 1 : 0);
    setOpenModal(true);
  };

  /**
   *
   * @param id
   */
  const submitTVARecoveredCar = (id: any, data: any) => {
    TVARecoveredVehicle(id, data);
  };

  /**
   *
   * @param id
   */
  async function TVARecoveredVehicle(id: any, data: any) {
    const url = `${urlEnum.TVARecovered}/${id}`;
    const TVAValue = { TvaRecovered: data };
    const res = (await updateData(url, TVAValue)) as any;
    if (!res || res?.error) {
      return;
    }
    getDataRefresh();
    setOpenModal(false);
  }

  /**
   *
   */
  const options = {
    filter: false,
    download: true,
    responsive: tableLayoutOnMobile,
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: false,
      },
      filename: (
        Vocabulary.dashboardFinancial +
        "_" +
        tableLabels[props.value].label +
        ".xlsx"
      ).toLowerCase(),
    },
    rowsPerPageOptions: [
      10,
      50,
      100,
      state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: pills.page,
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (state.vehicles[rowIndex].statusError) {
        if (state.vehicles[rowIndex].statusError.length === 1) {
          if (state.vehicles[rowIndex].statusError[0] === VehicleStatus.Unsold)
            return {
              style: {
                backgroundColor: "#ffccbb63",
                cursor: "pointer",
              },
            };
          else if (
            state.vehicles[rowIndex].statusError[0] ===
            VehicleStatus.NotReceived
          )
            return {
              style: {
                backgroundColor: "#cc43144d",
                cursor: "pointer",
              },
            };
        } else if (state.vehicles[rowIndex].statusError.length === 2) {
          return {
            style: {
              backgroundColor: "#4d5e8042",
              cursor: "pointer",
            },
          };
        }
      }
      return {
        style: { cursor: "pointer" },
      };
    },
    serverSide: true,
    rowsPerPage: pills.perPage,
    search: false,
    count: state?.vehiclesNumber,
    rowHover: true,
    onRowClick: (rowData: any, rowState: any) => {
      vehicleContext.updateVehicle({});
      navigate(`/vehiclesDashboard/${info.route}/${rowData[0]}`);
    },
    onChangePage: (page: number) => {
      navigate(
        `${localUrlEnum.financialDepartment}?status=${pills.status}?page=${
          page + 1
        }?perPage=${pills.perPage}?statusVehicul=${
          pills.statusVehicul ? pills.statusVehicul : null
        }?users=${pills.users ? pills.users : null}?company=${
          pills.company ? pills.company : null
        }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
          pills.vehicleTypes ? pills.vehicleTypes : null
        }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
          pills.visible
        }?statusError=${pills.statusError}${
          pills?.search ? `?search=${pills?.search}` : ""
        }?showIncompleteVehicles=${pills.showIncompleteVehicles}`
      );
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      navigate(
        `${localUrlEnum.financialDepartment}?${
          pills.status
        }?page=${1}?perPage=${numberOfRows}?statusVehicul=${
          pills.statusVehicul ? pills.statusVehicul : null
        }?users=${pills.users ? pills.users : null}?company=${
          pills.company ? pills.company : null
        }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
          pills.vehicleTypes ? pills.vehicleTypes : null
        }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
          pills.visible
        }?statusError=${pills.statusError}${
          pills?.search ? `?search=${pills?.search}` : ""
        }?showIncompleteVehicles=${pills.showIncompleteVehicles}`
      );
      window.scrollTo(0, 0);
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      const alteredData = data.map((column: any, index: number) => {
        column.data = column?.data?.map((value: any, key: number) => {
          if (typeof value == "object") {
            if (value) {
              const name = Object.values(value)[1];
              value = name;
            }
          } else if (columns[key].boolean) {
            value = value == "1" ? Vocabulary.yes : Vocabulary.no;
          }
          return value;
        });

        return column;
      });
      return `${buildHead(columns)}${buildBody(alteredData)}`;
    },
  };

  /**
   *
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const fileName = (
      Vocabulary.dashboardFinancial +
      "_" +
      tableLabels[value].label
    ).toLowerCase();
    const url = `${urlEnum.vehicles}/export/financial/table/data/as/excel`;

    exportTable(url, pills, fileName, exportedColumns);
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <div
      style={{
        marginBottom: isMobile() ? 70 : 0,
        marginLeft: isMobile() ? -15 : 0,
        marginRight: isMobile() ? -15 : -20,
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <Box style={{ marginBottom: -24, marginLeft: 9 }}>
          <DinamicTabs
            name={componentNames.DinamicTabs}
            labels={tableLabels}
            handleChangeTab={handleChange}
            value={value}
            openModalWindow={() =>
              navigate(`${localUrlEnum.vehicles}/vehicleDetails/newVehicle`)
            }
            search={true}
          ></DinamicTabs>
        </Box>
        <div
          style={{
            marginLeft: isMobile() ? 0 : -15,
            marginRight: isMobile() ? 0 : -15,
            width: "100%",
          }}
        >
          <TabPanel value={value} index={0}>
            <MUIDataTable
              key={pills.page}
              title={""}
              data={state?.vehicles}
              columns={columnsUnsold}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={columnsUnsold}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={columnsUnsold}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
        </div>
        <GenericModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            getDataRefresh();
          }}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>{Vocabulary.askForTVA}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className={styles.modalText}>{selectedCar?.rowData[1]}</p>
              <p style={{ fontSize: 20, marginLeft: 10, marginRight: 10 }}>
                {`cu seria:`}
              </p>
              <p className={styles.modalText}>{selectedCar?.rowData[2]}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() =>
                  submitTVARecoveredCar(selectedCar?.rowData[0], recoveredTVA)
                }
                variant="contained"
                style={{ color: "white" }}
              >
                {Vocabulary.yes}
              </Button>
              <Button
                variant="contained"
                style={{ color: "white", marginLeft: 30 }}
                onClick={() => {
                  setOpenModal(false);
                  getDataRefresh();
                }}
              >
                {Vocabulary.no}
              </Button>
            </div>
          </div>
        </GenericModal>
      </ThemeProvider>
    </div>
  );
}

export default withRole(MainTableFinancial);
