/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Box,
  //Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  // FormControlLabel,
  IconButton,
  Switch,
  // Slider,
  // Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PieChartIcon from "@mui/icons-material/PieChart";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { CacheContext } from "../Context/cacheContext";
import { ChangeEvent, SyntheticEvent, useContext, useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import style from "../Styles/filters.module.css";
import TuneIcon from "@mui/icons-material/Tune";
import { isMobile } from "../Utils/utils";
import { PillsProps } from "./MainDashboard";
import { VehiclesTab } from "../Utils/autocompleteEnum";
import { GenericModel, StatusModel } from "../Models/Models";
import SellIcon from "@mui/icons-material/Sell";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";

const VehicleStatus = {
  NotReceived: Vocabulary.received,
  Unsold: Vocabulary.preparationDelays,
} as const;

const VehicleStatusEN = {
  NotReceived: "NotReceived",
  Unsold: "Unsold",
  UnsoldNotReceived: "Unsold, NotReceived",
  TvaRecovered: "1",
  TvaToBeRecovered: "0",
} as const;

type VehicleStatusProps = {
  users: any;
  data: PillsProps;
  handleSliderChange: (event: any, newValue: any) => void;
  handleMinInputChangeURL: (event: any) => void;
  handleMaxInputChangeURL: (event: any) => void;
  onChangeTextField: (event: any, newValue: any, name: string) => void;
  changeStatusError: (statusError: string) => void;
  changeTvaRecovered: (tvaRecovered: string) => void;
  handleAccordion: (open: boolean) => void;
  setShowIncompleteVehicles: (
    event: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => void;
  statusesForFilters?: Array<StatusModel>;
  sellingStatuses?: Array<StatusModel>;
};
export default function VehicleSlider(props: VehicleStatusProps) {
  const navigate = useNavigate();
  const {
    users,
    data,
    // handleSliderChange,
    handleMinInputChangeURL,
    handleMaxInputChangeURL,
    onChangeTextField,
    changeStatusError,
    changeTvaRecovered,
    handleAccordion,
    setShowIncompleteVehicles,
    statusesForFilters,
    sellingStatuses,
  } = props;
  const cacheContext: any = useContext(CacheContext);
  const [hideFilters, setHideFilters] = useState(true);
  const [eventForMinPrice, setEventForMinPrice] = useState();
  const [eventForMaxPrice, setEventForMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState(data.minPrice);
  const [maxPrice, setMaxPrice] = useState(data.maxPrice);
  const routerLocation = useLocation();

  function handleChangeMinPrice(event: any) {
    if (eventForMinPrice && event?.key === "Enter")
      handleMinInputChangeURL(eventForMinPrice);
    if (eventForMinPrice && event?.type === "blur")
      handleMinInputChangeURL(eventForMinPrice);
  }
  function handleChangeMaxPrice(event: any) {
    if (eventForMaxPrice && event.key === "Enter")
      handleMaxInputChangeURL(eventForMaxPrice);
    if (eventForMaxPrice && event?.type === "blur")
      handleMaxInputChangeURL(eventForMaxPrice);
  }

  /**
   * Get the users for filtering depending on the status
   * @returns users for filtering
   */
  const getUsersOptions = (): GenericModel[] => {
    switch (data.status) {
      case VehiclesTab.sold:
        return users.vanzatori;
      case VehiclesTab.reserved:
        return users.useriReservari;
      default:
        return users.useriAchizitie;
    }
  };

  /**
   *
   * @returns values for autocomplete from url
   */
  const getValuesForMultipleAutocomplete = (value: string) => {
    const array: GenericModel[] = [];
    const object = Object.assign(data);
    if (object[value]) {
      const currentValue = object[value];
      if (JSON.parse(currentValue)?.length) {
        const ids = JSON.parse(currentValue);
        if (ids?.length) {
          let foundedObject = null;
          const cache = Object.assign(cacheContext.cache);
          const cacheUsers = getUsersOptions();
          switch (value) {
            case "statusVehicul":
              ids.forEach((id: number) => {
                foundedObject = sellingStatuses?.find((status: StatusModel) => {
                  return status.id == id;
                });
                if (foundedObject) array.push(foundedObject);
              });
              break;
            case "physicalVehicleStatus":
              ids.forEach((id: number) => {
                foundedObject = statusesForFilters?.find(
                  (status: StatusModel) => {
                    return status.id == id;
                  }
                );
                if (foundedObject) array.push(foundedObject);
              });
              break;
            case "users":
              ids.forEach((id: number) => {
                foundedObject = cacheUsers.find((type: GenericModel) => {
                  return type.id == id;
                });
                if (foundedObject) array.push(foundedObject);
              });
              break;
            default:
              ids.forEach((id: number) => {
                foundedObject = cache[value].find((type: GenericModel) => {
                  return type.id == id;
                });
                if (foundedObject) array.push(foundedObject);
              });

              break;
          }
        }
      }
    }
    return array;
  };

  /**
   *
   *
   */
  const handleChangeMultipleAutocomplete = (
    event: SyntheticEvent<Element, Event>,
    newValue: GenericModel[],
    changingValue: string
  ) => {
    const ids: any[] = [];
    if (newValue) {
      newValue?.forEach((status: GenericModel) => {
        ids.push(status?.id);
      });
    }
    onChangeTextField(event, ids, changingValue);
  };

  /**
   *
   * @param newValue
   */
  const handleChangeVisibility = (newValue: string) => {
    const pathname = routerLocation.pathname.split("/").filter(Boolean); // Split the path and remove empty strings
    const currentPath = pathname[pathname.length - 1]; // Get the last part of the path
    console.log("currentPath", currentPath);
    navigate(
      `?status=${data.status}?page=${1}?perPage=${data.perPage}?statusVehicul=${
        data.statusVehicul ? data.statusVehicul : null
      }?users=${data.users ? data.users : null}?company=${
        data.company ? data.company : null
      }?locations=${data.locations ? data.locations : null}?vehicleTypes=${
        data.vehicleTypes ? data.vehicleTypes : null
      }?minPrice=${data.minPrice}?maxPrice=${
        data.maxPrice
      }?visible=${newValue}?statusError=${data.statusError}?tvaRecovered=${
        data.tvaRecovered
      }${
        data?.search ? `?search=${data?.search}` : ""
      }?showIncompleteVehicles=${data.showIncompleteVehicles}?purchaseType=${
        data.purchaseType
      }?sortByCreatedAt=${data.sortByCreatedAt}`
    );
  };

  /**
   *
   * @returns visibility on website filter as a component
   */
  const visibilityFilter = () => {
    return (
      <Box
        component="fieldset"
        sx={{
          display: "flex",
          border: 0,
          marginTop: "2rem",
          textTransform: "capitalize",
          padding: 0,
        }}
      >
        <legend>
          <Typography>{Vocabulary.visibleOnSite}</Typography>
        </legend>
        <ToggleButtonGroup
          exclusive
          aria-label="Selected Items"
          color="primary"
          value={data.visible}
          sx={{ width: 1, marginTop: "0.5rem", borderRadius: "10px" }}
        >
          <Tooltip title="">
            <ToggleButton
              selected={data.visible == "null" || data.visible == null}
              aria-label="centered"
              fullWidth
              value="null"
              style={{ borderRadius: " 10px 0 0 10px" }}
              onClick={() => {
                handleChangeVisibility("null");
              }}
            >
              {Vocabulary.all}
            </ToggleButton>
          </Tooltip>
          <Tooltip title={Vocabulary.visibleOnSite}>
            <ToggleButton
              value="1"
              selected={data.visible == 1}
              aria-label="centered"
              fullWidth
              onClick={() => {
                handleChangeVisibility("1");
              }}
            >
              <VisibilityIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title={Vocabulary.notVisibleOnSite}>
            <ToggleButton
              value="0"
              selected={data.visible == "0"}
              aria-label="centered"
              fullWidth
              style={{ borderRadius: " 0 10px 10px 0 " }}
              onClick={() => {
                handleChangeVisibility("0");
              }}
            >
              <VisibilityOffIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
    );
  };

  // /**
  //  *
  //  * @returns
  //  */
  // const latestAddedCarsSort = () => {
  //   return (
  //     <Grid item xs={12} md={12}>
  //       <FormControlLabel
  //         label={
  //           <Typography style={{ fontSize: "1rem" }}>
  //             {Vocabulary.latestCars}
  //           </Typography>
  //         }
  //         control={
  //           <Switch
  //             checked={data?.sortByCreatedAt == "true"}
  //             onChange={(event: ChangeEvent<HTMLInputElement>) => {
  //               //props?.setShowIncompleteVehicles?.(e, e.target.checked); daca se vrea doar la vehicule
  //               onChangeTextField(
  //                 event,
  //                 event?.target.checked,
  //                 "sortByCreatedAt"
  //               );
  //             }}
  //           />
  //         }
  //       />
  //     </Grid>
  //   );
  // };

  /**
   *
   */
  const showCarsWithNegativeProfit = () => {
    return (
      <Grid item xs={12} md={12}>
        <FormControlLabel
          label={
            <Typography style={{ fontSize: "1rem" }}>
              {Vocabulary.vehiclesWithNegativeProfit}
            </Typography>
          }
          control={
            <Switch
              checked={data?.showNegativeProfit == "true"}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                //props?.setShowIncompleteVehicles?.(e, e.target.checked); daca se vrea doar la vehicule
                onChangeTextField(
                  event,
                  event?.target.checked,
                  "showNegativeProfit"
                );
              }}
            />
          }
        />
      </Grid>
    );
  };

  return (
    <div className={hideFilters ? style.container : style.containerHide}>
      <div className={hideFilters ? style.filters : style.filtersHide}>
        {data?.status != VehiclesTab.sold &&
          data?.status != VehiclesTab.partialVehicles && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
              <Autocomplete
                id="statusVehicul"
                freeSolo={false}
                fullWidth
                disablePortal
                multiple
                getOptionLabel={(option: any) => option.nume}
                size="small"
                value={getValuesForMultipleAutocomplete("statusVehicul") ?? []}
                options={sellingStatuses ?? []}
                onChange={(event: any, newValue: any) => {
                  handleChangeMultipleAutocomplete(
                    event,
                    newValue,
                    "statusVehicul"
                  );
                  handleAccordion(false);
                }}
                sx={{ maxWidth: "90%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status Vehicul"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
          )}
        {data?.status != VehiclesTab.sold &&
          data?.status != VehiclesTab.partialVehicles &&
          statusesForFilters?.length != 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
              <Autocomplete
                id="physicalVehicleStatus"
                freeSolo={false}
                fullWidth
                disablePortal
                multiple
                getOptionLabel={(option: any) => option.nume}
                size="small"
                value={
                  getValuesForMultipleAutocomplete("physicalVehicleStatus") ??
                  []
                }
                options={statusesForFilters ?? []}
                onChange={(event: any, newValue: any) => {
                  handleChangeMultipleAutocomplete(
                    event,
                    newValue,
                    "physicalVehicleStatus"
                  );
                  handleAccordion(false);
                }}
                sx={{ maxWidth: "90%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status Fizic Vehicul"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
          )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="users"
            multiple
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            fullWidth
            options={getUsersOptions()}
            value={getValuesForMultipleAutocomplete("users")}
            onChange={(event: any, newValue: any) => {
              handleChangeMultipleAutocomplete(event, newValue, "users");
              handleAccordion(false);
            }}
            sx={{ maxWidth: "90%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Utilizatori"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }}>
          <TaxiAlertIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="company"
            multiple
            freeSolo={false}
            fullWidth
            disablePortal
            getOptionLabel={(option: any) => option.nume_firma}
            size="small"
            options={cacheContext.cache.company}
            value={getValuesForMultipleAutocomplete("company")}
            onChange={(event: any, newValue: any) => {
              handleChangeMultipleAutocomplete(event, newValue, "company");
              handleAccordion(false);
            }}
            sx={{ maxWidth: "90%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Firme"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationCityIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="locations"
            multiple
            disablePortal
            freeSolo={false}
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={getValuesForMultipleAutocomplete("locations")}
            options={cacheContext.cache.locations}
            onChange={(event: any, newValue: any) => {
              handleChangeMultipleAutocomplete(event, newValue, "locations");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Locatie"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PieChartIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="vehicleTypes"
            multiple
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.vehicleTypes}
            onChange={(
              event: SyntheticEvent<Element, Event>,
              newValue: any[]
            ) => {
              handleChangeMultipleAutocomplete(event, newValue, "vehicleTypes");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            value={getValuesForMultipleAutocomplete("vehicleTypes")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tip vehicul"
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </Box>

        {data?.status != VehiclesTab.intermediated && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SellIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
            <Autocomplete
              id="purchaseType"
              multiple
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.nume}
              size="small"
              options={cacheContext.cache.purchaseType}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                newValue: any[]
              ) => {
                handleChangeMultipleAutocomplete(
                  event,
                  newValue,
                  "purchaseType"
                );
                handleAccordion(false);
              }}
              sx={{ width: "100%" }}
              value={getValuesForMultipleAutocomplete("purchaseType")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.purchaseType}
                  variant="outlined"
                  size="small"
                />
              )}
            ></Autocomplete>
          </Box>
        )}
        <Box sx={{ display: "flex", marginTop: 1.8 }}>
          <EuroSymbolIcon sx={{ color: "#4d5e80", mr: 2, my: 1 }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                value={minPrice}
                size="small"
                onChange={(e: any) => {
                  setEventForMinPrice(e);
                  setMinPrice(e?.target?.value);
                }}
                onBlur={(e: any) => handleChangeMinPrice(e)}
                onKeyUp={(e: any) => {
                  handleChangeMinPrice(e);
                }}
                label="Minim"
                style={{ marginLeft: -8 }}
                inputProps={{
                  step: 1000,
                  min: 0,
                  type: "number",
                }}
              />
              <TextField
                value={maxPrice}
                size="small"
                onChange={(e: any) => {
                  setEventForMaxPrice(e);
                  setMaxPrice(e?.target?.value);
                }}
                onKeyUp={(e: any) => {
                  handleChangeMaxPrice(e);
                }}
                onBlur={(e: any) => handleChangeMaxPrice(e)}
                label="Maxim"
                inputProps={{
                  step: 1000,
                  min: 0,
                  type: "number",
                }}
              />
            </div>
          </div>
        </Box>

        {
          //cele vandute si la comanda vor avea mereu vizibil pe site=0
          data?.status != VehiclesTab.sold &&
            data?.status != VehiclesTab.onDemandVehicles &&
            visibilityFilter()
        }

        <Grid
          container
          spacing={2}
          style={{ padding: "5px", marginTop: "10px" }}
        >
          <Grid item xs={12} md={12}>
            <FormControlLabel
              label={
                <Typography style={{ fontSize: "1rem" }}>
                  {Vocabulary.incompleteVehicles}
                </Typography>
              }
              control={
                <Switch
                  checked={data?.showIncompleteVehicles}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setShowIncompleteVehicles(e, e.target.checked);
                  }}
                />
              }
            />
          </Grid>
          {/* {latestAddedCarsSort()} */}
          {data?.status === VehiclesTab.sold && showCarsWithNegativeProfit()}
        </Grid>
        {
          <div>
            <div
              style={{
                border: "1px solid #0000001f",
                borderRadius: 10,
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor:
                    data?.tvaRecovered?.toString() ===
                    VehicleStatusEN.TvaRecovered
                      ? "#dbdbdb4d"
                      : "",
                }}
                onClick={() =>
                  data?.tvaRecovered?.toString() ===
                  VehicleStatusEN.TvaRecovered
                    ? changeTvaRecovered("null")
                    : changeTvaRecovered(VehicleStatusEN.TvaRecovered)
                }
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: "#49ab518c",
                      margin: 10,
                      borderRadius: 10,
                    }}
                  ></div>
                  <p
                    style={{
                      margin: 13,
                      verticalAlign: "center",
                      color: "#4d5e80",
                    }}
                  >
                    {"TVA de recuperat"}
                  </p>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor:
                    data?.tvaRecovered?.toString() ===
                    VehicleStatusEN.TvaToBeRecovered
                      ? "#dbdbdb4d"
                      : "",
                }}
                onClick={() =>
                  data?.tvaRecovered?.toString() ===
                  VehicleStatusEN.TvaToBeRecovered
                    ? changeTvaRecovered("null")
                    : changeTvaRecovered(VehicleStatusEN.TvaToBeRecovered)
                }
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: "#7a7a7a63",
                      margin: 10,
                      borderRadius: 10,
                    }}
                  ></div>
                  <p
                    style={{
                      margin: 13,
                      verticalAlign: "center",
                      color: "#4d5e80",
                    }}
                  >
                    {"TVA recuperat"}
                  </p>
                </div>
              </div>

              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor:
                    data?.statusError === VehicleStatusEN.Unsold
                      ? "#dbdbdb4d"
                      : "",
                }}
                onClick={() =>
                  data?.statusError === VehicleStatusEN.Unsold
                    ? changeStatusError("null")
                    : changeStatusError(VehicleStatusEN.Unsold)
                }
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: "#ffccbb63",
                      margin: 10,
                      borderRadius: 10,
                    }}
                  ></div>
                  <p
                    style={{
                      margin: 13,
                      verticalAlign: "center",
                      color: "#4d5e80",
                    }}
                  >
                    {VehicleStatus.Unsold}
                  </p>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  backgroundColor:
                    data?.statusError === VehicleStatusEN.NotReceived
                      ? "#dbdbdb4d"
                      : "",
                }}
                onClick={() =>
                  data?.statusError === VehicleStatusEN.NotReceived
                    ? changeStatusError("null")
                    : changeStatusError(VehicleStatusEN.NotReceived)
                }
              >
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#cc43144d",
                    margin: 10,
                    borderRadius: 10,
                  }}
                ></div>
                <p
                  style={{
                    margin: 13,
                    verticalAlign: "center",
                    color: "#4d5e80",
                  }}
                >
                  {VehicleStatus.NotReceived}
                </p>
              </div>
            </div>
          </div>
        }
      </div>

      <div style={{ position: "absolute", bottom: 10, left: 8 }}>
        {isMobile() ? null : hideFilters ? (
          <Tooltip title="Ascunde filtre">
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#4448", color: "#fff" }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filtre">
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#ff6633", color: "#fff" }}
            >
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
